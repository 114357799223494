import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const DEFAULT_TITLE = 'CBL IT Services';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: "Home"
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

router.afterEach((to) => {
    Vue.nextTick(() => {
        document.title = `${to.meta.title}${to.meta.title ? ' | ' : ''}${DEFAULT_TITLE}`;
    });
});

export default router
