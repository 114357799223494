<template>
  <div class="home">
    
    <b-row class="mt-5">
      <b-col class="text-center">
        <p>
          If you wish to contact me regarding development or an issue with an app then 
          please email <a href="mailto:developer@chrislongden.co.uk">developer@chrislongden.co.uk</a>
        </p>
      </b-col>
    </b-row>

  </div>
</template>

<script>
export default {
  name: 'Home'
}
</script>
